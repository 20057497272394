import { CropInfo } from '@web-apps/cropper';

import { Ratio } from '@/types/ratio';

import { UploaderImage, UploaderStatus } from './image';

export interface UploaderJob {
  id: string;
  dpi?: number;
  ratio?: Ratio;
  status: UploaderStatus;
  images: UploaderImage<UploaderStatus>[];
  imageStatusChangedCallbacks: UploaderImageStatusChangedCallback<UploaderStatus>[];
  jobStatusChangedCallbacks: UploaderJobStatusChangedCallback<UploaderStatus>[];
}

export const enum UploaderReducerActionType {
  CreateJob = 'CREATE_JOB',
  SetImageAsPreparing = 'SET_IMAGE_AS_PREPARING',
  SetImageAsPrepared = 'SET_IMAGE_AS_PREPARED',
  SetImageAsUploading = 'SET_IMAGE_AS_UPLOADING',
  SetImageAsUploaded = 'SET_IMAGE_AS_UPLOADED',
  SetImageAsFailed = 'SET_IMAGE_AS_FAILED',
  RemoveImage = 'REMOVE_IMAGE',
}

export type UploaderJobReducerAction =
  | {
      type: UploaderReducerActionType.CreateJob;
      payload: {
        dpi?: number;
        ratio?: Ratio;
        images: File[];
        skipQualityResize: boolean;
        imageStatusChangedCallbacks: UploaderImageStatusChangedCallback<UploaderStatus>[];
        jobStatusChangedCallbacks: UploaderJobStatusChangedCallback<UploaderStatus>[];
      };
    }
  | {
      type: UploaderReducerActionType.SetImageAsPreparing;
      payload: {
        id: string;
      };
    }
  | {
      type: UploaderReducerActionType.SetImageAsPrepared;
      payload: {
        id: string;
        blobUrl: string;
        width: number;
        height: number;
        newFile?: File;
        crop: CropInfo;
      };
    }
  | {
      type: UploaderReducerActionType.SetImageAsUploading;
      payload: {
        id: string;
      };
    }
  | {
      type: UploaderReducerActionType.SetImageAsUploaded;
      payload: {
        id: string;
        url: string;
        storageKey: string;
      };
    }
  | {
      type: UploaderReducerActionType.SetImageAsFailed;
      payload: {
        id: string;
        error: Error;
      };
    }
  | {
      type: UploaderReducerActionType.RemoveImage;
      payload: {
        id: string;
      };
    };

export type UploaderJobStatusChangedCallback<T extends UploaderStatus> = {
  statuses: T[];
  callback: (job: UploaderJob) => void;
};

export type UploaderImageStatusChangedCallback<T extends UploaderStatus> = {
  statuses: T[];
  // status UploaderFileStatus.ADDED is not handled because it's the initial status
  callback: (image: UploaderImage<T>) => void;
};
