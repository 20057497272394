import cloneDeep from 'lodash/cloneDeep';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

import { UploaderImage, UploaderStatus } from './types/image';
import {
  UploaderJob,
  UploaderJobReducerAction,
  UploaderReducerActionType,
} from './types/job';
import generateUploaderFileId from './utils/images/generateUploaderFileId';
import getImageById from './utils/images/getImageById';
import updateJobsWithNextImage from './utils/job/updateJobsWithNextImage';
import getNextStatus from './utils/status/getNextStatus';
import isImageStatusAdded from './utils/status/isImageStatusAdded';
import isImageStatusPrepared from './utils/status/isImageStatusPrepared';
import isImageStatusPreparing from './utils/status/isImageStatusPreparing';
import isImageStatusUploading from './utils/status/isImageStatusUploading';
import isStatusAfter from './utils/status/isStatusAfter';

const reducer = (state: UploaderJob[], action: UploaderJobReducerAction) => {
  switch (action.type) {
    // ADD JOB WITH IMAGES
    case UploaderReducerActionType.CreateJob: {
      const {
        skipQualityResize,
        images,
        imageStatusChangedCallbacks: fileStatusChangedCallbacks,
        jobStatusChangedCallbacks,
        dpi,
        ratio,
      } = action.payload;

      const newImages: UploaderImage<UploaderStatus.Added>[] = [];
      images.forEach((image: File) => {
        newImages.push({
          status: UploaderStatus.Added,
          previousStatuses: [],
          id: generateUploaderFileId(image),
          nativeFile: image,
          name: image.name,
          size: image.size,
          skipQualityResize,
        });
      });
      const newJob: UploaderJob = {
        id: `job_${uuidv4()}`,
        images: newImages,
        status: UploaderStatus.Added,
        imageStatusChangedCallbacks: fileStatusChangedCallbacks,
        dpi,
        ratio,
        jobStatusChangedCallbacks,
      };
      const nextState = cloneDeep(state);
      nextState.push(newJob);
      return nextState;
    }
    // SET IMAGE AS PREPARING
    case UploaderReducerActionType.SetImageAsPreparing: {
      const { id } = action.payload;
      const imageToProcess = getImageById(state, id, UploaderStatus.Added);

      if (imageToProcess && isImageStatusAdded(imageToProcess)) {
        const updatedImage: UploaderImage<UploaderStatus.Preparing> = {
          ...imageToProcess,
          status: UploaderStatus.Preparing,
          previousStatuses: imageToProcess.previousStatuses.concat(
            imageToProcess.status,
          ),
        };
        return updateJobsWithNextImage<UploaderStatus.Preparing>(
          state,
          updatedImage,
        );
      }
      return state;
    }

    // SET IMAGE AS PREPARED
    case UploaderReducerActionType.SetImageAsPrepared: {
      const { id, newFile, blobUrl, width, height, crop } = action.payload;
      const imageToProcess = getImageById(state, id, UploaderStatus.Preparing);

      if (imageToProcess && isImageStatusPreparing(imageToProcess)) {
        // Removing nativeFile to clear memory
        const { nativeFile, ...cleanImageToProcess } = imageToProcess;

        const updateImage: UploaderImage<UploaderStatus.Prepared> = {
          ...cleanImageToProcess,
          status: UploaderStatus.Prepared,
          crop,
          previousStatuses: cleanImageToProcess.previousStatuses.concat(
            cleanImageToProcess.status,
          ),
          newFile,
          blobUrl,
          width,
          height,
        };
        return updateJobsWithNextImage<UploaderStatus.Prepared>(
          state,
          updateImage,
        );
      }
      return state;
    }

    // SET IMAGE AS UPLOADING
    case UploaderReducerActionType.SetImageAsUploading: {
      const { id } = action.payload;
      const imageToProcess = getImageById(state, id, UploaderStatus.Prepared);

      if (imageToProcess && isImageStatusPrepared(imageToProcess)) {
        const updatedImage: UploaderImage<UploaderStatus.Uploading> = {
          ...imageToProcess,
          status: UploaderStatus.Uploading,
          previousStatuses: imageToProcess.previousStatuses.concat(
            imageToProcess.status,
          ),
        };
        return updateJobsWithNextImage<UploaderStatus.Uploading>(
          state,
          updatedImage,
        );
      }
      return state;
    }

    // SET IMAGE AS UPLOADED
    case UploaderReducerActionType.SetImageAsUploaded: {
      const { id, url, storageKey } = action.payload;
      const imageToProcess = getImageById(state, id, UploaderStatus.Uploading);

      if (imageToProcess && isImageStatusUploading(imageToProcess)) {
        const updateImage: UploaderImage<UploaderStatus.Uploaded> = {
          ...imageToProcess,
          url,
          storageKey,
          status: UploaderStatus.Uploaded,
          previousStatuses: imageToProcess.previousStatuses.concat(
            imageToProcess.status,
          ),
        };
        return updateJobsWithNextImage<UploaderStatus.Uploaded>(
          state,
          updateImage,
        );
      }
      return state;
    }

    // SET IMAGE AS FAILED
    case UploaderReducerActionType.SetImageAsFailed: {
      const { id, error } = action.payload;
      const imageToProcess = getImageById(state, id);

      if (imageToProcess) {
        const updateImage: UploaderImage<UploaderStatus.Failed> = {
          id: imageToProcess.id,
          name: imageToProcess.name,
          size: imageToProcess.size,
          error,
          status: UploaderStatus.Failed,
          previousStatuses: imageToProcess.previousStatuses.concat(
            imageToProcess.status,
          ),
        };
        return updateJobsWithNextImage<UploaderStatus.Failed>(
          state,
          updateImage,
        );
      }
      return state;
    }

    case UploaderReducerActionType.RemoveImage: {
      const { id } = action.payload;
      const jobWithImage = cloneDeep(state).find(({ images }) =>
        images.some(({ id: imageId }) => imageId === id),
      );
      if (!jobWithImage) {
        return state;
      }
      const nextState = cloneDeep(state);
      const jobIndex = state.findIndex(
        ({ id: jobId }) => jobId === jobWithImage.id,
      );

      // If the Job status is behind the image status because we removed an image we update the job status
      if (jobWithImage.images.length > 1) {
        while (
          jobWithImage.images.every((img) =>
            isStatusAfter(img.status, jobWithImage.status),
          )
        ) {
          jobWithImage.status = getNextStatus(jobWithImage.status);
        }
      } else {
        // if the job has no more images we set the job status to failed
        jobWithImage.status = UploaderStatus.Failed;
      }

      nextState.splice(jobIndex, 1, {
        ...jobWithImage,
        images: jobWithImage.images.filter(({ id: imageId }) => imageId !== id),
      });

      return nextState;
    }

    default:
      return state;
  }
};

export default reducer;
