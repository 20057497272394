import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/app/layout/AdBlockLogger/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/app/layout/AttributionLogger/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/app/layout/LogsLoop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/app/layout/Smartlook/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/app/layout/WebVitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/shop/app/styles/main.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/app/apps/shop/components/GoogleTagManager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/components/LazyToaster/LazyToaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/context/CartSideEffects/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/context/History/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/shop/context/Uploader/provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.7_@playwright+test@1.45.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Mulish\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mulish\"}],\"variableName\":\"mulish\"}");
